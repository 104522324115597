<template>
    <div class="page">
        <div v-if="unsubscribed" class="container">You have successfully unsubscribed!</div>
        <div v-if="error" class="container">{{ error }}</div>
        <loader v-if="loading"/>
    </div>
</template>

<script>
    import axios from "axios";
    import Loader from "@/components/ui/Loader"
    export default {
        components: {
            Loader
        },
        data() {
            return {
                loading: false,
                token: this.$route.params.id,
                unsubscribed: false,
                error: ""
            }
        },
        async mounted() {
            if(!this.token) {
                this.$router.push({ name: "home" })
            }
            await this.unsubscribe()
        },
        methods: {
            async unsubscribe() {
                try {
                    this.loading = true
                    const { data } = await axios.delete(process.env.VUE_APP_BASE_API_URL + `/subscription/unsubscribe/${this.token}`); 
                    this.unsubscribed = true
                } catch(error) {
                    console.log("error - ", error)
                    this.error = "You have already unsubscribed."
                } finally {
                    this.loading = false
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.page {
  padding-top: 78px;
  padding-bottom: 100px;
}
.container {
    max-width: 1280px;
    padding: 0;
    height: 100%;
}
</style>